.App {
  text-align: center;
}

.nav-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background: #0c1b0d;
  color: white;
}

.nav-links {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: end;
  list-style: none;
}

.nav-style {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
}

.nav-bar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.home {
  background-image: url('/public/images/mix-bg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 70px;
}

.home-descriptions {
 font-size: x-large;
 font-style: italic;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-grid {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.image-background {
  color: white;
  background-color: #670f0f;
  display: flex;
  flex-direction: column;
}
